import { useCallback, useEffect } from "react"


function useSubscribeToPrint() {

    const printResults = useCallback(() => {

        window.focus()

        window.print()

    }, [])

    const handlePrintEvent = useCallback((event: MessageEvent) => {

        if(event.origin.startsWith(`${process.env.REACT_APP_CRM_URL}`)) {

            printResults()

        }

        return

    }, [printResults])

    useEffect(() => {

        window.addEventListener(`message`, handlePrintEvent)

        return () => {

            window.removeEventListener(`message`, handlePrintEvent)

        }

    }, [handlePrintEvent])

}

export default useSubscribeToPrint