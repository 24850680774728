import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { Role } from 'api/types/Role'
import { User } from 'api/types/User'

const initialState: User = {
    ID: 0,
    Email: ``,
    FirstName: ``,
    FullName: ``,
    LastName: ``,
    PIN: 0,
    Role: Role.Advisor
}

const slice = createSlice({
    name: `user`,
    initialState,
    reducers: {
        setUser: (state, action: PayloadAction<User>) => action.payload,
        resetUser: () => initialState,
    },
})

export const {
    setUser,
    resetUser,
} = slice.actions

export default slice.reducer