import apiFetch from "api/apiFetch"

const updateEstimateMessage = (estimateKey: string, message: string): Promise<void> => {

    return apiFetch(`api/mpvi/estimate/${estimateKey}/message`, {
        method: `PATCH`,
        body: JSON.stringify(message)
    })

}

export default updateEstimateMessage