import React from 'react'
import useSubscribeToUrl from 'hooks/useSubscribeToUrl'
import BaseView from 'components/BaseView'
import useSubscribeToPrint from 'hooks/useSubscribeToPrint'

function App() {
    useSubscribeToUrl()
    useSubscribeToPrint()
    
    return (
        <BaseView/>
    )
}

export default App