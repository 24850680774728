enum MPVIStatus {
    NotStarted,
    InProgress,
    WaitingOnEstimate,
    WaitingOnApproval,
    MpviSent,
    MpviOpened,
    WaitingOnParts,
    ServiceDeclined,
    VehicleInService,
    Complete
}

export default MPVIStatus