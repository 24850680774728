import { combineReducers } from '@reduxjs/toolkit'
import user from 'modules/user'
import advisorInspectionView from 'modules/advisorInspectionView'
import app from 'modules/app'
import customer from 'modules/customer'
import customerInspectionView from 'modules/customerInspectionView'
import dealer from 'modules/dealer'
import estimateMessage from 'modules/estimateMessage'
import vehicle from 'modules/vehicle'
import videos from 'modules/videos'

const rootReducer = combineReducers({
    user,
    advisorInspectionView,
    app,
    customer,
    customerInspectionView,
    dealer,
    vehicle,
    videos,
    estimateMessage
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer