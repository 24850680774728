import { useEffect } from 'react'
import { getEstimate } from 'modules/app'
import { useAppDispatch, useTypedSelector } from 'store'

function useSubscribeToEstimate() {
    const dispatch = useAppDispatch()
    const {
        estimateKey
    } = useTypedSelector(state => state.app)
    
    useEffect(() => {
        if (estimateKey) {
            dispatch(getEstimate(estimateKey))
        }
    }, [dispatch, estimateKey])
}

export default useSubscribeToEstimate