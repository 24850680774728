import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import Customer from 'api/types/Customer'

const initialState: Customer = {
    ID: 0,
    FirstName: ``,
    LastName: ``,
    Phone: ``,
}

const slice = createSlice({
    name: `customer`,
    initialState,
    reducers: {
        setCustomer: (state, action: PayloadAction<Customer>) => action.payload,
        resetCustomer: () => initialState,
    },
})

export const {
    setCustomer,
    resetCustomer,
} = slice.actions

export default slice.reducer