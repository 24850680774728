async function apiFetch(url: string, req?: Partial<RequestInit>) {
    const request: RequestInit = {
        headers: {
            'Content-Type': 'application/json'
        },
        ...req,
    }
    
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}`, request)

        const text = await response.text()
        const json = text.length ? JSON.parse(text) : {}

        if(!response.ok) {
            if (`Message` in json) {
                throw new Error(json.Message)
            } else {
                throw new Error(`Uncaught`)
            }
        }

        return json
    } catch (err) {
        throw new Error(`An error has occured while attempting to fetch the following resource: ${url}. ${err}`)
    }
}

export default apiFetch 