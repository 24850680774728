import userModes from 'enums/userModes'
import { initAppFailure, initAppFinish, initAppStart } from 'modules/app'
import { useEffect } from 'react'
import { useAppDispatch } from 'store'

function useSubscribeToUrl() {
    const dispatch = useAppDispatch()

    useEffect(() => {
        try {
            dispatch(initAppStart())
            let keyIndex: number
            const url = new URL(window.location.href)
            const pathsArray = url.pathname.split(`/`)

            //remove the leading `/`
            pathsArray.shift()

            pathsArray[0] === `mpvi-estimate` ? keyIndex = 1 : keyIndex = 0

            const estimateKey: string = pathsArray[keyIndex]
            const isInAdvisorMode: boolean = !!url.searchParams.get(`m`)

            if (!estimateKey) {
                throw new Error(`Estimate key not provided. Please contact support if the problem persists`)
            }

            dispatch(initAppFinish({
                estimateKey,
                userMode: isInAdvisorMode ? userModes.ADVISOR : userModes.CUSTOMER,
            }))
        } catch (error) {
            if (error instanceof Error) {
                dispatch(initAppFailure(error.message))
            } else {
                throw new Error(error)
            }
        }
    }, [dispatch])
}

export default useSubscribeToUrl