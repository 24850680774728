import {
    createEntityAdapter,
    createSlice,
} from '@reduxjs/toolkit'
import Video from 'api/types/Video'
import { RootState } from 'rootReducer'

export const videosAdapter = createEntityAdapter<Video>({
    selectId: (video) => video.ID
})

const slice = createSlice({
    name: `videos`,
    initialState: videosAdapter.getInitialState(),
    reducers: {
        videosReceived: videosAdapter.setAll
    },
})

export const {
    videosReceived
} = slice.actions

export const videosSelectors = videosAdapter.getSelectors<RootState>(state => state.videos)

export default slice.reducer