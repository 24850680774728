import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import Dealer from 'api/types/Dealer'

const initialState: Dealer = {
    ID: 0,
    Name: ``,
    Address1: ``,
    Address2: ``,
    City: ``,
    Region: ``,
    PostalCode: ``,
    ServicePhoneNumber: ``,
    TaxRate: 0,
}

const slice = createSlice({
    name: `dealer`,
    initialState,
    reducers: {
        setDealer: (state, action: PayloadAction<Dealer>) => action.payload,
        resetDealer: () => initialState,
    },
})

export const {
    setDealer,
    resetDealer,
} = slice.actions

export default slice.reducer