import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'

interface IEstimateMessageState {

    message: string

}

const initialState: IEstimateMessageState = {

    message: ``

}

const slice = createSlice({
    name: `estimateMessage`,
    initialState: initialState,
    reducers: {
        estimateMessageReceived(state, action: PayloadAction<string>) {

            state.message = action.payload

        },
    },
})

export const {
    estimateMessageReceived
} = slice.actions

export default slice.reducer