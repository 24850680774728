import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'store'
import MaterialUiProvider from 'providers/MaterialUiProvider'

const BaseProvider = ({children}) => {
    return (
        //keep these on the same level since it doesnt matter anyway
        <>
            <Provider store={store}>
            <MaterialUiProvider>
            <Router>
                {children}
            </Router>
            </MaterialUiProvider>
            </Provider>
        </>
    )
}

export default BaseProvider