enum modes {
    UNKNOWN,
    SUMMARY,
    VIDEO,
    INSPECTION,
    ERROR,
    LOADING,
    CONFIRMATION,
    REVIEW,
}

export default modes