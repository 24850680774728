import React from 'react'
import ReactDOM from 'react-dom'
import App from 'components/App'
import BaseProvider from 'providers/BaseProvider'
import * as serviceWorker from './serviceWorker'

import 'assets/styles/base.module.scss'

const render = () => {
    ReactDOM.render(
        <BaseProvider>
            <App/>
        </BaseProvider>,
        document.getElementById('root')
    )
}

render()

if (process.env.NODE_ENV === 'development' && module.hot) {
    module.hot.accept('components/App', render)
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()